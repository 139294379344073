/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { couponControllerCreate } from '../fn/coupon/coupon-controller-create';
import { CouponControllerCreate$Params } from '../fn/coupon/coupon-controller-create';
import { couponControllerDelete } from '../fn/coupon/coupon-controller-delete';
import { CouponControllerDelete$Params } from '../fn/coupon/coupon-controller-delete';
import { couponControllerFindById } from '../fn/coupon/coupon-controller-find-by-id';
import { CouponControllerFindById$Params } from '../fn/coupon/coupon-controller-find-by-id';
import { couponControllerGetAll } from '../fn/coupon/coupon-controller-get-all';
import { CouponControllerGetAll$Params } from '../fn/coupon/coupon-controller-get-all';
import { couponControllerGetAvailable } from '../fn/coupon/coupon-controller-get-available';
import { CouponControllerGetAvailable$Params } from '../fn/coupon/coupon-controller-get-available';
import { couponControllerSearchCursor } from '../fn/coupon/coupon-controller-search-cursor';
import { CouponControllerSearchCursor$Params } from '../fn/coupon/coupon-controller-search-cursor';
import { couponControllerSearchOffset } from '../fn/coupon/coupon-controller-search-offset';
import { CouponControllerSearchOffset$Params } from '../fn/coupon/coupon-controller-search-offset';
import { couponControllerSetDefault } from '../fn/coupon/coupon-controller-set-default';
import { CouponControllerSetDefault$Params } from '../fn/coupon/coupon-controller-set-default';
import { couponControllerUpdate } from '../fn/coupon/coupon-controller-update';
import { CouponControllerUpdate$Params } from '../fn/coupon/coupon-controller-update';
import { CouponDto } from '../models/coupon-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class CouponService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `couponControllerGetAvailable()` */
  static readonly CouponControllerGetAvailablePath = '/api/coupon/available';

  /**
   * 등록 가능한 쿠폰 조회.
   *
   * 현재 등록이 가능한 쿠폰 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerGetAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerGetAvailable$Response(params?: CouponControllerGetAvailable$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CouponDto>>> {
    return couponControllerGetAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * 등록 가능한 쿠폰 조회.
   *
   * 현재 등록이 가능한 쿠폰 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerGetAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerGetAvailable(params?: CouponControllerGetAvailable$Params, context?: HttpContext): Observable<Array<CouponDto>> {
    return this.couponControllerGetAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CouponDto>>): Array<CouponDto> => r.body)
    );
  }

  /** Path part for operation `couponControllerGetAll()` */
  static readonly CouponControllerGetAllPath = '/api/coupon/all';

  /**
   * 모든 쿠폰 조회.
   *
   * (관리자) 모든 쿠폰을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerGetAll$Response(params?: CouponControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CouponDto>>> {
    return couponControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 쿠폰 조회.
   *
   * (관리자) 모든 쿠폰을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerGetAll(params?: CouponControllerGetAll$Params, context?: HttpContext): Observable<Array<CouponDto>> {
    return this.couponControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CouponDto>>): Array<CouponDto> => r.body)
    );
  }

  /** Path part for operation `couponControllerFindById()` */
  static readonly CouponControllerFindByIdPath = '/api/coupon/{id}';

  /**
   * 쿠폰 조회.
   *
   * 쿠폰을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerFindById$Response(params: CouponControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponDto>> {
    return couponControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 조회.
   *
   * 쿠폰을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerFindById(params: CouponControllerFindById$Params, context?: HttpContext): Observable<CouponDto> {
    return this.couponControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponDto>): CouponDto => r.body)
    );
  }

  /** Path part for operation `couponControllerDelete()` */
  static readonly CouponControllerDeletePath = '/api/coupon/{id}';

  /**
   * 쿠폰 삭제.
   *
   * 쿠폰을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerDelete$Response(params: CouponControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return couponControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 삭제.
   *
   * 쿠폰을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerDelete(params: CouponControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.couponControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `couponControllerUpdate()` */
  static readonly CouponControllerUpdatePath = '/api/coupon/{id}';

  /**
   * 쿠폰 수정.
   *
   * 쿠폰을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  couponControllerUpdate$Response(params: CouponControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponDto>> {
    return couponControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 수정.
   *
   * 쿠폰을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  couponControllerUpdate(params: CouponControllerUpdate$Params, context?: HttpContext): Observable<CouponDto> {
    return this.couponControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponDto>): CouponDto => r.body)
    );
  }

  /** Path part for operation `couponControllerSearchOffset()` */
  static readonly CouponControllerSearchOffsetPath = '/api/coupon/search/offset';

  /**
   * 쿠폰 검색.
   *
   * 쿠폰을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSearchOffset$Response(params: CouponControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<CouponDto>;
}>> {
    return couponControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 검색.
   *
   * 쿠폰을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSearchOffset(params: CouponControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<CouponDto>;
}> {
    return this.couponControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<CouponDto>;
}>): OffsetPaginationDto & {
'items'?: Array<CouponDto>;
} => r.body)
    );
  }

  /** Path part for operation `couponControllerSearchCursor()` */
  static readonly CouponControllerSearchCursorPath = '/api/coupon/search/cursor';

  /**
   * 쿠폰 검색.
   *
   * 쿠폰을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSearchCursor$Response(params: CouponControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<CouponDto>;
}>> {
    return couponControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 검색.
   *
   * 쿠폰을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSearchCursor(params: CouponControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<CouponDto>;
}> {
    return this.couponControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<CouponDto>;
}>): CursorPaginationDto & {
'items'?: Array<CouponDto>;
} => r.body)
    );
  }

  /** Path part for operation `couponControllerCreate()` */
  static readonly CouponControllerCreatePath = '/api/coupon';

  /**
   * 쿠폰 생성.
   *
   * 쿠폰을 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  couponControllerCreate$Response(params: CouponControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponDto>> {
    return couponControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 쿠폰 생성.
   *
   * 쿠폰을 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  couponControllerCreate(params: CouponControllerCreate$Params, context?: HttpContext): Observable<CouponDto> {
    return this.couponControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponDto>): CouponDto => r.body)
    );
  }

  /** Path part for operation `couponControllerSetDefault()` */
  static readonly CouponControllerSetDefaultPath = '/api/coupon/default/{id}';

  /**
   * 기본 쿠폰 설정.
   *
   * 기본 쿠폰을 설정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `couponControllerSetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSetDefault$Response(params: CouponControllerSetDefault$Params, context?: HttpContext): Observable<StrictHttpResponse<CouponDto>> {
    return couponControllerSetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * 기본 쿠폰 설정.
   *
   * 기본 쿠폰을 설정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `couponControllerSetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  couponControllerSetDefault(params: CouponControllerSetDefault$Params, context?: HttpContext): Observable<CouponDto> {
    return this.couponControllerSetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<CouponDto>): CouponDto => r.body)
    );
  }

}
