/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthCodeDto } from '../models/auth-code-dto';
import { FindEmailResultDto } from '../models/find-email-result-dto';
import { FindPasswordTokenDto } from '../models/find-password-token-dto';
import { memberControllerBlock } from '../fn/member/member-controller-block';
import { MemberControllerBlock$Params } from '../fn/member/member-controller-block';
import { memberControllerCheck } from '../fn/member/member-controller-check';
import { MemberControllerCheck$Params } from '../fn/member/member-controller-check';
import { memberControllerCompleteOAuth } from '../fn/member/member-controller-complete-o-auth';
import { MemberControllerCompleteOAuth$Params } from '../fn/member/member-controller-complete-o-auth';
import { memberControllerDelete } from '../fn/member/member-controller-delete';
import { MemberControllerDelete$Params } from '../fn/member/member-controller-delete';
import { memberControllerFindByEmail } from '../fn/member/member-controller-find-by-email';
import { MemberControllerFindByEmail$Params } from '../fn/member/member-controller-find-by-email';
import { memberControllerFindById } from '../fn/member/member-controller-find-by-id';
import { MemberControllerFindById$Params } from '../fn/member/member-controller-find-by-id';
import { memberControllerFindByTel } from '../fn/member/member-controller-find-by-tel';
import { MemberControllerFindByTel$Params } from '../fn/member/member-controller-find-by-tel';
import { memberControllerFindEmail } from '../fn/member/member-controller-find-email';
import { MemberControllerFindEmail$Params } from '../fn/member/member-controller-find-email';
import { memberControllerFindPassword } from '../fn/member/member-controller-find-password';
import { MemberControllerFindPassword$Params } from '../fn/member/member-controller-find-password';
import { memberControllerGetAll } from '../fn/member/member-controller-get-all';
import { MemberControllerGetAll$Params } from '../fn/member/member-controller-get-all';
import { memberControllerGetMe } from '../fn/member/member-controller-get-me';
import { MemberControllerGetMe$Params } from '../fn/member/member-controller-get-me';
import { memberControllerLogin } from '../fn/member/member-controller-login';
import { MemberControllerLogin$Params } from '../fn/member/member-controller-login';
import { memberControllerLogout } from '../fn/member/member-controller-logout';
import { MemberControllerLogout$Params } from '../fn/member/member-controller-logout';
import { memberControllerRequestAuthCode } from '../fn/member/member-controller-request-auth-code';
import { MemberControllerRequestAuthCode$Params } from '../fn/member/member-controller-request-auth-code';
import { memberControllerSearch } from '../fn/member/member-controller-search';
import { MemberControllerSearch$Params } from '../fn/member/member-controller-search';
import { memberControllerSetPassword } from '../fn/member/member-controller-set-password';
import { MemberControllerSetPassword$Params } from '../fn/member/member-controller-set-password';
import { memberControllerSignout } from '../fn/member/member-controller-signout';
import { MemberControllerSignout$Params } from '../fn/member/member-controller-signout';
import { memberControllerSignUp } from '../fn/member/member-controller-sign-up';
import { MemberControllerSignUp$Params } from '../fn/member/member-controller-sign-up';
import { memberControllerUnblock } from '../fn/member/member-controller-unblock';
import { MemberControllerUnblock$Params } from '../fn/member/member-controller-unblock';
import { memberControllerUpdate } from '../fn/member/member-controller-update';
import { MemberControllerUpdate$Params } from '../fn/member/member-controller-update';
import { memberControllerVerifyAuthCode } from '../fn/member/member-controller-verify-auth-code';
import { MemberControllerVerifyAuthCode$Params } from '../fn/member/member-controller-verify-auth-code';
import { MemberDto } from '../models/member-dto';
import { MemberWithAccessTokenDto } from '../models/member-with-access-token-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { TokensDto } from '../models/tokens-dto';

@Injectable({ providedIn: 'root' })
export class MemberService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `memberControllerGetMe()` */
  static readonly MemberControllerGetMePath = '/api/member/me';

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerGetMe$Response(params?: MemberControllerGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<(MemberDto | MemberWithAccessTokenDto)>> {
    return memberControllerGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerGetMe(params?: MemberControllerGetMe$Params, context?: HttpContext): Observable<(MemberDto | MemberWithAccessTokenDto)> {
    return this.memberControllerGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<(MemberDto | MemberWithAccessTokenDto)>): (MemberDto | MemberWithAccessTokenDto) => r.body)
    );
  }

  /** Path part for operation `memberControllerSearch()` */
  static readonly MemberControllerSearchPath = '/api/member/search/offset';

  /**
   * 회원 검색.
   *
   * 회원을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerSearch$Response(params: MemberControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<MemberDto>;
}>> {
    return memberControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 검색.
   *
   * 회원을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerSearch(params: MemberControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<MemberDto>;
}> {
    return this.memberControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<MemberDto>;
}>): OffsetPaginationDto & {
'items'?: Array<MemberDto>;
} => r.body)
    );
  }

  /** Path part for operation `memberControllerGetAll()` */
  static readonly MemberControllerGetAllPath = '/api/member/all';

  /**
   * 모든 회원 조회.
   *
   * (관리자) 모든 회원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerGetAll$Response(params?: MemberControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MemberDto>>> {
    return memberControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 회원 조회.
   *
   * (관리자) 모든 회원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerGetAll(params?: MemberControllerGetAll$Params, context?: HttpContext): Observable<Array<MemberDto>> {
    return this.memberControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MemberDto>>): Array<MemberDto> => r.body)
    );
  }

  /** Path part for operation `memberControllerCheck()` */
  static readonly MemberControllerCheckPath = '/api/member/check';

  /**
   * 회원 중복 확인.
   *
   * 회원 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerCheck$Response(params?: MemberControllerCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return memberControllerCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 중복 확인.
   *
   * 회원 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerCheck(params?: MemberControllerCheck$Params, context?: HttpContext): Observable<boolean> {
    return this.memberControllerCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `memberControllerFindById()` */
  static readonly MemberControllerFindByIdPath = '/api/member/{id}';

  /**
   * 회원 조회.
   *
   * 회원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindById$Response(params: MemberControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 조회.
   *
   * 회원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindById(params: MemberControllerFindById$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerDelete()` */
  static readonly MemberControllerDeletePath = '/api/member/{id}';

  /**
   * 회원 삭제.
   *
   * 회원 계정을 삭제 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerDelete$Response(params: MemberControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 삭제.
   *
   * 회원 계정을 삭제 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerDelete(params: MemberControllerDelete$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerFindByTel()` */
  static readonly MemberControllerFindByTelPath = '/api/member/tel/{tel}';

  /**
   * 전화번호로 회원 조회.
   *
   * 입력한 전화번호로 등록된 회원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerFindByTel()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindByTel$Response(params: MemberControllerFindByTel$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerFindByTel(this.http, this.rootUrl, params, context);
  }

  /**
   * 전화번호로 회원 조회.
   *
   * 입력한 전화번호로 등록된 회원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerFindByTel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindByTel(params: MemberControllerFindByTel$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerFindByTel$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerFindByEmail()` */
  static readonly MemberControllerFindByEmailPath = '/api/member/email/{email}';

  /**
   * 회원 조회.
   *
   * 회원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerFindByEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindByEmail$Response(params: MemberControllerFindByEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerFindByEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 조회.
   *
   * 회원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerFindByEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerFindByEmail(params: MemberControllerFindByEmail$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerFindByEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerLogin()` */
  static readonly MemberControllerLoginPath = '/api/member/login';

  /**
   * 회원 로그인.
   *
   * 회원 로그인을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerLogin$Response(params: MemberControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<TokensDto>> {
    return memberControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 로그인.
   *
   * 회원 로그인을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerLogin(params: MemberControllerLogin$Params, context?: HttpContext): Observable<TokensDto> {
    return this.memberControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokensDto>): TokensDto => r.body)
    );
  }

  /** Path part for operation `memberControllerLogout()` */
  static readonly MemberControllerLogoutPath = '/api/member/logout';

  /**
   * 회원 로그아웃.
   *
   * 회원 로그아웃을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerLogout$Response(params?: MemberControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return memberControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 로그아웃.
   *
   * 회원 로그아웃을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerLogout(params?: MemberControllerLogout$Params, context?: HttpContext): Observable<void> {
    return this.memberControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `memberControllerRequestAuthCode()` */
  static readonly MemberControllerRequestAuthCodePath = '/api/member/code';

  /**
   * 인증 번호 요청.
   *
   * 인증 번호를 요청합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerRequestAuthCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerRequestAuthCode$Response(params: MemberControllerRequestAuthCode$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthCodeDto>> {
    return memberControllerRequestAuthCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 인증 번호 요청.
   *
   * 인증 번호를 요청합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerRequestAuthCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerRequestAuthCode(params: MemberControllerRequestAuthCode$Params, context?: HttpContext): Observable<AuthCodeDto> {
    return this.memberControllerRequestAuthCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthCodeDto>): AuthCodeDto => r.body)
    );
  }

  /** Path part for operation `memberControllerVerifyAuthCode()` */
  static readonly MemberControllerVerifyAuthCodePath = '/api/member/code/verify';

  /**
   * 인증 번호 확인.
   *
   * 인증 번호를 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerVerifyAuthCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerVerifyAuthCode$Response(params: MemberControllerVerifyAuthCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return memberControllerVerifyAuthCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 인증 번호 확인.
   *
   * 인증 번호를 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerVerifyAuthCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerVerifyAuthCode(params: MemberControllerVerifyAuthCode$Params, context?: HttpContext): Observable<void> {
    return this.memberControllerVerifyAuthCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `memberControllerSignUp()` */
  static readonly MemberControllerSignUpPath = '/api/member/signup';

  /**
   * 회원가입.
   *
   * 회원으로 가입합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerSignUp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSignUp$Response(params: MemberControllerSignUp$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerSignUp(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원가입.
   *
   * 회원으로 가입합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerSignUp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSignUp(params: MemberControllerSignUp$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerSignUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerFindEmail()` */
  static readonly MemberControllerFindEmailPath = '/api/member/find-email';

  /**
   * 이메일 찾기.
   *
   * 이메일을 찾습니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerFindEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerFindEmail$Response(params: MemberControllerFindEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<FindEmailResultDto>> {
    return memberControllerFindEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 찾기.
   *
   * 이메일을 찾습니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerFindEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerFindEmail(params: MemberControllerFindEmail$Params, context?: HttpContext): Observable<FindEmailResultDto> {
    return this.memberControllerFindEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindEmailResultDto>): FindEmailResultDto => r.body)
    );
  }

  /** Path part for operation `memberControllerFindPassword()` */
  static readonly MemberControllerFindPasswordPath = '/api/member/find-password';

  /**
   * 비밀번호 찾기.
   *
   * 비밀번호를 찾습니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerFindPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerFindPassword$Response(params: MemberControllerFindPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<FindPasswordTokenDto>> {
    return memberControllerFindPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 찾기.
   *
   * 비밀번호를 찾습니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerFindPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerFindPassword(params: MemberControllerFindPassword$Params, context?: HttpContext): Observable<FindPasswordTokenDto> {
    return this.memberControllerFindPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindPasswordTokenDto>): FindPasswordTokenDto => r.body)
    );
  }

  /** Path part for operation `memberControllerUpdate()` */
  static readonly MemberControllerUpdatePath = '/api/member';

  /**
   * 내 정보 수정.
   *
   * 내 정보를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerUpdate$Response(params: MemberControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보 수정.
   *
   * 내 정보를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerUpdate(params: MemberControllerUpdate$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerCompleteOAuth()` */
  static readonly MemberControllerCompleteOAuthPath = '/api/member/oauth/complete';

  /**
   * 소셜 계정 가입 완료.
   *
   * 소셜 계정 가입을 완료 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerCompleteOAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerCompleteOAuth$Response(params: MemberControllerCompleteOAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return memberControllerCompleteOAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * 소셜 계정 가입 완료.
   *
   * 소셜 계정 가입을 완료 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerCompleteOAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerCompleteOAuth(params: MemberControllerCompleteOAuth$Params, context?: HttpContext): Observable<void> {
    return this.memberControllerCompleteOAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `memberControllerBlock()` */
  static readonly MemberControllerBlockPath = '/api/member/{id}/block';

  /**
   * 회원 차단.
   *
   * 회원 계정을 차단합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerBlock$Response(params: MemberControllerBlock$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerBlock(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 차단.
   *
   * 회원 계정을 차단합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerBlock(params: MemberControllerBlock$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerUnblock()` */
  static readonly MemberControllerUnblockPath = '/api/member/{id}/unblock';

  /**
   * 회원 차단 해제.
   *
   * 회원 계정을 차단 해제 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerUnblock()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerUnblock$Response(params: MemberControllerUnblock$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerUnblock(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 차단 해제.
   *
   * 회원 계정을 차단 해제 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerUnblock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  memberControllerUnblock(params: MemberControllerUnblock$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerUnblock$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerSetPassword()` */
  static readonly MemberControllerSetPasswordPath = '/api/member/set-password';

  /**
   * 비밀번호 변경.
   *
   * 비밀번호를 변경 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerSetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSetPassword$Response(params: MemberControllerSetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerSetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 변경.
   *
   * 비밀번호를 변경 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerSetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSetPassword(params: MemberControllerSetPassword$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerSetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

  /** Path part for operation `memberControllerSignout()` */
  static readonly MemberControllerSignoutPath = '/api/member/signout';

  /**
   * 회원 탈퇴.
   *
   * 회원 계정을 탈퇴 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `memberControllerSignout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSignout$Response(params: MemberControllerSignout$Params, context?: HttpContext): Observable<StrictHttpResponse<MemberDto>> {
    return memberControllerSignout(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 탈퇴.
   *
   * 회원 계정을 탈퇴 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `memberControllerSignout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  memberControllerSignout(params: MemberControllerSignout$Params, context?: HttpContext): Observable<MemberDto> {
    return this.memberControllerSignout$Response(params, context).pipe(
      map((r: StrictHttpResponse<MemberDto>): MemberDto => r.body)
    );
  }

}
