import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ButtonComponent,
  InputEmailComponent,
  InputTextComponent,
  InputPasswordComponent,
  InputTelComponent,
  ToastService,
  AlertService,
  ModalService,
  AlertComponent,
  CustomValidators,
} from '@common/angular';
import { MemberService } from '@api-client';
import { IconComponent } from '@common/angular';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-find-password',
  imports: [
    IonContent,
    CommonModule,
    ModalHeaderComponent,
    FormsModule,
    InputEmailComponent,
    InputTextComponent,
    InputTelComponent,
    ReactiveFormsModule,
    ButtonComponent,
    InputPasswordComponent,
    IconComponent,
  ],
  templateUrl: './find-password.modal.html',
  styleUrl: './find-password.modal.scss',
})
export class FindPasswordModal extends BaseModal {
  service = inject(MemberService);
  toast = inject(ToastService);
  modalService = inject(ModalService);
  modal!: IonModal;

  verified = signal<boolean>(false);
  token = signal<string | undefined>(undefined);
  updated = signal<boolean>(false);

  findPasswordForm = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    tel: new FormControl('', {
      validators: [Validators.required],
      asyncValidators: [CustomValidators.memberTel(this.service)],
      nonNullable: true,
    }),
  });

  resetPasswordForm = new FormGroup({
    password: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    passwordConfirm: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  submit() {
    if (this.findPasswordForm.invalid) return;
    this.service
      .memberControllerFindPassword({
        body: this.findPasswordForm.getRawValue(),
      })
      .subscribe(({ token }) => {
        this.verified.set(true);
        this.token.set(token);
      });
  }

  setPassword() {
    if (this.resetPasswordForm.invalid) return;
    if (this.resetPasswordForm.controls.password.value !== this.resetPasswordForm.controls.passwordConfirm.value) {
      this.toast.error('비밀번호가 일치하지 않습니다.');
      return;
    }

    this.service
      .memberControllerSetPassword({
        body: { ...this.resetPasswordForm.getRawValue(), token: this.token()! },
      })
      .subscribe(() => {
        this.openResultModal();
      });
  }

  openResultModal() {
    this.modalService
      .open(AlertComponent, {
        componentProps: {
          title: '재설정 완료',
          content: ['비밀번호 변경이 완료되었습니다.', '다시 로그인해주세요.'],
          icons: '/icons/checked.svg',
        },
      })
      .closed.subscribe(() => this.modal.dismiss());
  }
}
