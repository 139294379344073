/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { coBuyingApplyControllerCancelCoBuying } from '../fn/co-buying-apply/co-buying-apply-controller-cancel-co-buying';
import { CoBuyingApplyControllerCancelCoBuying$Params } from '../fn/co-buying-apply/co-buying-apply-controller-cancel-co-buying';
import { coBuyingApplyControllerCheckExistCoBuying } from '../fn/co-buying-apply/co-buying-apply-controller-check-exist-co-buying';
import { CoBuyingApplyControllerCheckExistCoBuying$Params } from '../fn/co-buying-apply/co-buying-apply-controller-check-exist-co-buying';
import { coBuyingApplyControllerCreate } from '../fn/co-buying-apply/co-buying-apply-controller-create';
import { CoBuyingApplyControllerCreate$Params } from '../fn/co-buying-apply/co-buying-apply-controller-create';
import { coBuyingApplyControllerFindAll } from '../fn/co-buying-apply/co-buying-apply-controller-find-all';
import { CoBuyingApplyControllerFindAll$Params } from '../fn/co-buying-apply/co-buying-apply-controller-find-all';
import { coBuyingApplyControllerFindById } from '../fn/co-buying-apply/co-buying-apply-controller-find-by-id';
import { CoBuyingApplyControllerFindById$Params } from '../fn/co-buying-apply/co-buying-apply-controller-find-by-id';
import { coBuyingApplyControllerMy } from '../fn/co-buying-apply/co-buying-apply-controller-my';
import { CoBuyingApplyControllerMy$Params } from '../fn/co-buying-apply/co-buying-apply-controller-my';
import { coBuyingApplyControllerMyTotal } from '../fn/co-buying-apply/co-buying-apply-controller-my-total';
import { CoBuyingApplyControllerMyTotal$Params } from '../fn/co-buying-apply/co-buying-apply-controller-my-total';
import { coBuyingApplyControllerRemoveByAdmin } from '../fn/co-buying-apply/co-buying-apply-controller-remove-by-admin';
import { CoBuyingApplyControllerRemoveByAdmin$Params } from '../fn/co-buying-apply/co-buying-apply-controller-remove-by-admin';
import { coBuyingApplyControllerRemoveByMember } from '../fn/co-buying-apply/co-buying-apply-controller-remove-by-member';
import { CoBuyingApplyControllerRemoveByMember$Params } from '../fn/co-buying-apply/co-buying-apply-controller-remove-by-member';
import { coBuyingApplyControllerUpdate } from '../fn/co-buying-apply/co-buying-apply-controller-update';
import { CoBuyingApplyControllerUpdate$Params } from '../fn/co-buying-apply/co-buying-apply-controller-update';
import { coBuyingApplyControllerUpdateStatus } from '../fn/co-buying-apply/co-buying-apply-controller-update-status';
import { CoBuyingApplyControllerUpdateStatus$Params } from '../fn/co-buying-apply/co-buying-apply-controller-update-status';
import { CoBuyingApplyCountDto } from '../models/co-buying-apply-count-dto';
import { CoBuyingApplyDto } from '../models/co-buying-apply-dto';

@Injectable({ providedIn: 'root' })
export class CoBuyingApplyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `coBuyingApplyControllerFindAll()` */
  static readonly CoBuyingApplyControllerFindAllPath = '/api/co-buying-apply';

  /**
   * 공동구매 신청내역 조회.
   *
   * (관리자) 모든 공동구매 신청내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerFindAll$Response(params?: CoBuyingApplyControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoBuyingApplyDto>>> {
    return coBuyingApplyControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청내역 조회.
   *
   * (관리자) 모든 공동구매 신청내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerFindAll(params?: CoBuyingApplyControllerFindAll$Params, context?: HttpContext): Observable<Array<CoBuyingApplyDto>> {
    return this.coBuyingApplyControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoBuyingApplyDto>>): Array<CoBuyingApplyDto> => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerCreate()` */
  static readonly CoBuyingApplyControllerCreatePath = '/api/co-buying-apply';

  /**
   * 공동구매 신청.
   *
   * 공동구매를 신청합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingApplyControllerCreate$Response(params: CoBuyingApplyControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청.
   *
   * 공동구매를 신청합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingApplyControllerCreate(params: CoBuyingApplyControllerCreate$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerMy()` */
  static readonly CoBuyingApplyControllerMyPath = '/api/co-buying-apply/my';

  /**
   * 유저 공동구매 신청내역 조회.
   *
   * 유저 ID로 공동구매 신청내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerMy$Response(params?: CoBuyingApplyControllerMy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoBuyingApplyDto>>> {
    return coBuyingApplyControllerMy(this.http, this.rootUrl, params, context);
  }

  /**
   * 유저 공동구매 신청내역 조회.
   *
   * 유저 ID로 공동구매 신청내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerMy(params?: CoBuyingApplyControllerMy$Params, context?: HttpContext): Observable<Array<CoBuyingApplyDto>> {
    return this.coBuyingApplyControllerMy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoBuyingApplyDto>>): Array<CoBuyingApplyDto> => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerMyTotal()` */
  static readonly CoBuyingApplyControllerMyTotalPath = '/api/co-buying-apply/my/total';

  /**
   * 유저 공동구매 신청내역 카운트 조회.
   *
   * 유저 ID로 공동구매 신청내역 카운트를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerMyTotal()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerMyTotal$Response(params?: CoBuyingApplyControllerMyTotal$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyCountDto>> {
    return coBuyingApplyControllerMyTotal(this.http, this.rootUrl, params, context);
  }

  /**
   * 유저 공동구매 신청내역 카운트 조회.
   *
   * 유저 ID로 공동구매 신청내역 카운트를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerMyTotal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerMyTotal(params?: CoBuyingApplyControllerMyTotal$Params, context?: HttpContext): Observable<CoBuyingApplyCountDto> {
    return this.coBuyingApplyControllerMyTotal$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyCountDto>): CoBuyingApplyCountDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerCheckExistCoBuying()` */
  static readonly CoBuyingApplyControllerCheckExistCoBuyingPath = '/api/co-buying-apply/{id}/check';

  /**
   * 공동구매 신청 유무 확인.
   *
   * 공동구매 ID와 유저 ID로 공동구매 신청 유무를 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerCheckExistCoBuying()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerCheckExistCoBuying$Response(params: CoBuyingApplyControllerCheckExistCoBuying$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return coBuyingApplyControllerCheckExistCoBuying(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청 유무 확인.
   *
   * 공동구매 ID와 유저 ID로 공동구매 신청 유무를 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerCheckExistCoBuying$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerCheckExistCoBuying(params: CoBuyingApplyControllerCheckExistCoBuying$Params, context?: HttpContext): Observable<boolean> {
    return this.coBuyingApplyControllerCheckExistCoBuying$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerFindById()` */
  static readonly CoBuyingApplyControllerFindByIdPath = '/api/co-buying-apply/{id}';

  /**
   * ID로 공동구매 신청내역 조회.
   *
   * ID로 공동구매 신청내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerFindById$Response(params: CoBuyingApplyControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * ID로 공동구매 신청내역 조회.
   *
   * ID로 공동구매 신청내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerFindById(params: CoBuyingApplyControllerFindById$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerUpdate()` */
  static readonly CoBuyingApplyControllerUpdatePath = '/api/co-buying-apply/{id}';

  /**
   * 공동구매 신청내역 수정.
   *
   * 공동구매 신청내역을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingApplyControllerUpdate$Response(params: CoBuyingApplyControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청내역 수정.
   *
   * 공동구매 신청내역을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingApplyControllerUpdate(params: CoBuyingApplyControllerUpdate$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerCancelCoBuying()` */
  static readonly CoBuyingApplyControllerCancelCoBuyingPath = '/api/co-buying-apply/cancel/{id}';

  /**
   * 공동구매 신청 취소.
   *
   * 공동구매 신청을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerCancelCoBuying()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerCancelCoBuying$Response(params: CoBuyingApplyControllerCancelCoBuying$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerCancelCoBuying(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청 취소.
   *
   * 공동구매 신청을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerCancelCoBuying$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerCancelCoBuying(params: CoBuyingApplyControllerCancelCoBuying$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerCancelCoBuying$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerUpdateStatus()` */
  static readonly CoBuyingApplyControllerUpdateStatusPath = '/api/co-buying-apply/status/{id}';

  /**
   * 공동구매 신청상태 변경.
   *
   * (관리자) 공동구매 신청 상태(확정 / 취소)를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerUpdateStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerUpdateStatus$Response(params: CoBuyingApplyControllerUpdateStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerUpdateStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청상태 변경.
   *
   * (관리자) 공동구매 신청 상태(확정 / 취소)를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerUpdateStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerUpdateStatus(params: CoBuyingApplyControllerUpdateStatus$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerUpdateStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerRemoveByMember()` */
  static readonly CoBuyingApplyControllerRemoveByMemberPath = '/api/co-buying-apply/member/{id}';

  /**
   * 공동구매 신청 삭제.
   *
   * (사용자)공동구매 신청내역을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerRemoveByMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerRemoveByMember$Response(params: CoBuyingApplyControllerRemoveByMember$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerRemoveByMember(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청 삭제.
   *
   * (사용자)공동구매 신청내역을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerRemoveByMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerRemoveByMember(params: CoBuyingApplyControllerRemoveByMember$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerRemoveByMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

  /** Path part for operation `coBuyingApplyControllerRemoveByAdmin()` */
  static readonly CoBuyingApplyControllerRemoveByAdminPath = '/api/co-buying-apply/admin/{id}';

  /**
   * 공동구매 신청 삭제.
   *
   * (관리자)공동구매 신청내역을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingApplyControllerRemoveByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerRemoveByAdmin$Response(params: CoBuyingApplyControllerRemoveByAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingApplyDto>> {
    return coBuyingApplyControllerRemoveByAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 신청 삭제.
   *
   * (관리자)공동구매 신청내역을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingApplyControllerRemoveByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingApplyControllerRemoveByAdmin(params: CoBuyingApplyControllerRemoveByAdmin$Params, context?: HttpContext): Observable<CoBuyingApplyDto> {
    return this.coBuyingApplyControllerRemoveByAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingApplyDto>): CoBuyingApplyDto => r.body)
    );
  }

}
