/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateReservationDto } from '../models/create-reservation-dto';
import { MyWaitingDto } from '../models/my-waiting-dto';
import { reservationControllerCall } from '../fn/reservation/reservation-controller-call';
import { ReservationControllerCall$Params } from '../fn/reservation/reservation-controller-call';
import { reservationControllerCancel } from '../fn/reservation/reservation-controller-cancel';
import { ReservationControllerCancel$Params } from '../fn/reservation/reservation-controller-cancel';
import { reservationControllerCreate } from '../fn/reservation/reservation-controller-create';
import { ReservationControllerCreate$Params } from '../fn/reservation/reservation-controller-create';
import { reservationControllerExist } from '../fn/reservation/reservation-controller-exist';
import { ReservationControllerExist$Params } from '../fn/reservation/reservation-controller-exist';
import { reservationControllerFindAll } from '../fn/reservation/reservation-controller-find-all';
import { ReservationControllerFindAll$Params } from '../fn/reservation/reservation-controller-find-all';
import { reservationControllerFindByUserId } from '../fn/reservation/reservation-controller-find-by-user-id';
import { ReservationControllerFindByUserId$Params } from '../fn/reservation/reservation-controller-find-by-user-id';
import { reservationControllerGetMyWaiting } from '../fn/reservation/reservation-controller-get-my-waiting';
import { ReservationControllerGetMyWaiting$Params } from '../fn/reservation/reservation-controller-get-my-waiting';
import { reservationControllerGetStats } from '../fn/reservation/reservation-controller-get-stats';
import { ReservationControllerGetStats$Params } from '../fn/reservation/reservation-controller-get-stats';
import { reservationControllerSeat } from '../fn/reservation/reservation-controller-seat';
import { ReservationControllerSeat$Params } from '../fn/reservation/reservation-controller-seat';
import { reservationControllerSetPending } from '../fn/reservation/reservation-controller-set-pending';
import { ReservationControllerSetPending$Params } from '../fn/reservation/reservation-controller-set-pending';
import { reservationControllerUpdate } from '../fn/reservation/reservation-controller-update';
import { ReservationControllerUpdate$Params } from '../fn/reservation/reservation-controller-update';
import { reservationControllerUpdateStats } from '../fn/reservation/reservation-controller-update-stats';
import { ReservationControllerUpdateStats$Params } from '../fn/reservation/reservation-controller-update-stats';
import { ReservationDto } from '../models/reservation-dto';
import { ReservationStatsWithAllDto } from '../models/reservation-stats-with-all-dto';
import { ReservationStatusDto } from '../models/reservation-status-dto';

@Injectable({ providedIn: 'root' })
export class ReservationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reservationControllerFindAll()` */
  static readonly ReservationControllerFindAllPath = '/api/reservation';

  /**
   * 모든 예약 조회.
   *
   * 등록된 모든 예약을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerFindAll$Response(params?: ReservationControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationStatusDto>> {
    return reservationControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 예약 조회.
   *
   * 등록된 모든 예약을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerFindAll(params?: ReservationControllerFindAll$Params, context?: HttpContext): Observable<ReservationStatusDto> {
    return this.reservationControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationStatusDto>): ReservationStatusDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerCreate()` */
  static readonly ReservationControllerCreatePath = '/api/reservation';

  /**
   * 예약 등록.
   *
   * 새로운 예약을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerCreate$Response(params: ReservationControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateReservationDto>> {
    return reservationControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 등록.
   *
   * 새로운 예약을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerCreate(params: ReservationControllerCreate$Params, context?: HttpContext): Observable<CreateReservationDto> {
    return this.reservationControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateReservationDto>): CreateReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerGetStats()` */
  static readonly ReservationControllerGetStatsPath = '/api/reservation/stats';

  /**
   * 웨이팅 통계.
   *
   * 웨이팅 통계를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerGetStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerGetStats$Response(params?: ReservationControllerGetStats$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationStatsWithAllDto>> {
    return reservationControllerGetStats(this.http, this.rootUrl, params, context);
  }

  /**
   * 웨이팅 통계.
   *
   * 웨이팅 통계를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerGetStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerGetStats(params?: ReservationControllerGetStats$Params, context?: HttpContext): Observable<ReservationStatsWithAllDto> {
    return this.reservationControllerGetStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationStatsWithAllDto>): ReservationStatsWithAllDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerUpdateStats()` */
  static readonly ReservationControllerUpdateStatsPath = '/api/reservation/stats';

  /**
   * 웨이팅 통계 업데이트.
   *
   * 웨이팅 통계를 업데이트 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerUpdateStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerUpdateStats$Response(params?: ReservationControllerUpdateStats$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return reservationControllerUpdateStats(this.http, this.rootUrl, params, context);
  }

  /**
   * 웨이팅 통계 업데이트.
   *
   * 웨이팅 통계를 업데이트 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerUpdateStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerUpdateStats(params?: ReservationControllerUpdateStats$Params, context?: HttpContext): Observable<boolean> {
    return this.reservationControllerUpdateStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `reservationControllerFindByUserId()` */
  static readonly ReservationControllerFindByUserIdPath = '/api/reservation/{id}/user';

  /**
   * 회원 웨이팅 내역.
   *
   * 회원 ID로 웨이팅 내역을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerFindByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerFindByUserId$Response(params: ReservationControllerFindByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReservationDto>>> {
    return reservationControllerFindByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 웨이팅 내역.
   *
   * 회원 ID로 웨이팅 내역을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerFindByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerFindByUserId(params: ReservationControllerFindByUserId$Params, context?: HttpContext): Observable<Array<ReservationDto>> {
    return this.reservationControllerFindByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReservationDto>>): Array<ReservationDto> => r.body)
    );
  }

  /** Path part for operation `reservationControllerGetMyWaiting()` */
  static readonly ReservationControllerGetMyWaitingPath = '/api/reservation/{id}/my-waiting';

  /**
   * 웨이팅 현황 조회.
   *
   * 웨이팅 ID로 내 웨이팅 현황을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerGetMyWaiting()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerGetMyWaiting$Response(params: ReservationControllerGetMyWaiting$Params, context?: HttpContext): Observable<StrictHttpResponse<MyWaitingDto>> {
    return reservationControllerGetMyWaiting(this.http, this.rootUrl, params, context);
  }

  /**
   * 웨이팅 현황 조회.
   *
   * 웨이팅 ID로 내 웨이팅 현황을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerGetMyWaiting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerGetMyWaiting(params: ReservationControllerGetMyWaiting$Params, context?: HttpContext): Observable<MyWaitingDto> {
    return this.reservationControllerGetMyWaiting$Response(params, context).pipe(
      map((r: StrictHttpResponse<MyWaitingDto>): MyWaitingDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerSeat()` */
  static readonly ReservationControllerSeatPath = '/api/reservation/{id}/seat';

  /**
   * 예약 완료.
   *
   * 예약을 완료합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerSeat()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSeat$Response(params: ReservationControllerSeat$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerSeat(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 완료.
   *
   * 예약을 완료합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerSeat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSeat(params: ReservationControllerSeat$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerSeat$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerCall()` */
  static readonly ReservationControllerCallPath = '/api/reservation/{id}/call';

  /**
   * 예약 호출.
   *
   * 예약을 호출합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCall()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCall$Response(params: ReservationControllerCall$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerCall(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 호출.
   *
   * 예약을 호출합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCall$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCall(params: ReservationControllerCall$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerSetPending()` */
  static readonly ReservationControllerSetPendingPath = '/api/reservation/{id}/pending';

  /**
   * 예약 등록.
   *
   * 예약을 재등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerSetPending()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSetPending$Response(params: ReservationControllerSetPending$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerSetPending(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 등록.
   *
   * 예약을 재등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerSetPending$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerSetPending(params: ReservationControllerSetPending$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerSetPending$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerCancel()` */
  static readonly ReservationControllerCancelPath = '/api/reservation/{id}/cancel';

  /**
   * 예약 취소.
   *
   * 예약을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCancel$Response(params: ReservationControllerCancel$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerCancel(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 취소.
   *
   * 예약을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerCancel(params: ReservationControllerCancel$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerCancel$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerExist()` */
  static readonly ReservationControllerExistPath = '/api/reservation/{id}/exist';

  /**
   * 예약 없음.
   *
   * 예약을 없음 상태로 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerExist$Response(params: ReservationControllerExist$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerExist(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 없음.
   *
   * 예약을 없음 상태로 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationControllerExist(params: ReservationControllerExist$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

  /** Path part for operation `reservationControllerUpdate()` */
  static readonly ReservationControllerUpdatePath = '/api/reservation/{id}/update';

  /**
   * 예약 수정.
   *
   * 예약을 내용을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerUpdate$Response(params: ReservationControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationDto>> {
    return reservationControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 예약 수정.
   *
   * 예약을 내용을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationControllerUpdate(params: ReservationControllerUpdate$Params, context?: HttpContext): Observable<ReservationDto> {
    return this.reservationControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationDto>): ReservationDto => r.body)
    );
  }

}
