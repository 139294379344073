import { Component, ElementRef, inject, input, viewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';
import { IconComponent } from '../icon/icon.component';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-input-text',
  imports: [FormsModule, IconComponent],
  styleUrl: '../common/input.common.scss',
  template: `
    <div>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <input
          #inputRef
          class="w-full"
          type="text"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
          [autofocus]="autofocus()"
          [value]="value()"
        />
        @if (icon()) {
        <app-icon [name]="icon()!" class="size-6" [class]="iconClass()" />
        } @if (suffix()) {
        <span class="min-w-fit">{{ suffix() }}</span>
        }
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextComponent,
      multi: true,
    },
  ],
})
export class InputTextComponent extends BaseInputComponent<string> {
  icon = input<string>();
  iconClass = input<string>();
  suffix = input<string>();
  inputRef = viewChild<ElementRef<HTMLInputElement>>('inputRef');

  async ngAfterViewInit() {
    this.value$.subscribe((value) => {
      if (value) {
        this.inputRef()!.nativeElement.value = value!;
      }
    });

    fromEvent(this.inputRef()!.nativeElement, 'input')
      .pipe(debounceTime(this.debounce()))
      .subscribe((ev: any) => {
        this.value.set(ev.target.value);
      });
  }
}
