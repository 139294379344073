import { Component, inject, signal } from '@angular/core';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { IonContent } from '@ionic/angular/standalone';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MemberService } from '@api-client';
import { InputTextComponent, ButtonComponent, InputTelComponent, CustomValidators } from '@common/angular';
import { IonModal } from '@ionic/angular/common';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-find-email',
  imports: [IonContent, ModalHeaderComponent, FormsModule, ReactiveFormsModule, InputTextComponent, ButtonComponent, InputTelComponent],
  templateUrl: './find-email.modal.html',
  styleUrl: './find-email.modal.scss',
})
export class FindEmailModal extends BaseModal {
  service = inject(MemberService);

  modal!: IonModal;

  form = new FormGroup({
    name: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    tel: new FormControl('', {
      validators: [Validators.required],
      asyncValidators: [CustomValidators.memberTel(this.service)],
      nonNullable: true,
    }),
  });

  emailFound = signal<string | undefined>(undefined);

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.service
      .memberControllerFindEmail({
        body: this.form.getRawValue(),
      })
      .subscribe({
        next: ({ email }) => {
          this.emailFound.set(email);
        },
      });
  }
}
