/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { kioskModeControllerCreate } from '../fn/kiosk-mode/kiosk-mode-controller-create';
import { KioskModeControllerCreate$Params } from '../fn/kiosk-mode/kiosk-mode-controller-create';
import { kioskModeControllerFindById } from '../fn/kiosk-mode/kiosk-mode-controller-find-by-id';
import { KioskModeControllerFindById$Params } from '../fn/kiosk-mode/kiosk-mode-controller-find-by-id';
import { kioskModeControllerGetKioskMode } from '../fn/kiosk-mode/kiosk-mode-controller-get-kiosk-mode';
import { KioskModeControllerGetKioskMode$Params } from '../fn/kiosk-mode/kiosk-mode-controller-get-kiosk-mode';
import { kioskModeControllerRemove } from '../fn/kiosk-mode/kiosk-mode-controller-remove';
import { KioskModeControllerRemove$Params } from '../fn/kiosk-mode/kiosk-mode-controller-remove';
import { kioskModeControllerUpdate } from '../fn/kiosk-mode/kiosk-mode-controller-update';
import { KioskModeControllerUpdate$Params } from '../fn/kiosk-mode/kiosk-mode-controller-update';
import { kioskModeControllerUpdateMode } from '../fn/kiosk-mode/kiosk-mode-controller-update-mode';
import { KioskModeControllerUpdateMode$Params } from '../fn/kiosk-mode/kiosk-mode-controller-update-mode';
import { KioskModeDto } from '../models/kiosk-mode-dto';

@Injectable({ providedIn: 'root' })
export class KioskModeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `kioskModeControllerGetKioskMode()` */
  static readonly KioskModeControllerGetKioskModePath = '/api/kiosk-mode';

  /**
   * 키오스크 모드 전체 조회.
   *
   * 키오스크 모드를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerGetKioskMode()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerGetKioskMode$Response(params?: KioskModeControllerGetKioskMode$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KioskModeDto>>> {
    return kioskModeControllerGetKioskMode(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 전체 조회.
   *
   * 키오스크 모드를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerGetKioskMode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerGetKioskMode(params?: KioskModeControllerGetKioskMode$Params, context?: HttpContext): Observable<Array<KioskModeDto>> {
    return this.kioskModeControllerGetKioskMode$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KioskModeDto>>): Array<KioskModeDto> => r.body)
    );
  }

  /** Path part for operation `kioskModeControllerCreate()` */
  static readonly KioskModeControllerCreatePath = '/api/kiosk-mode';

  /**
   * 키오스크 모드 생성.
   *
   * 키오스크 모드를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kioskModeControllerCreate$Response(params: KioskModeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<KioskModeDto>> {
    return kioskModeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 생성.
   *
   * 키오스크 모드를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kioskModeControllerCreate(params: KioskModeControllerCreate$Params, context?: HttpContext): Observable<KioskModeDto> {
    return this.kioskModeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<KioskModeDto>): KioskModeDto => r.body)
    );
  }

  /** Path part for operation `kioskModeControllerFindById()` */
  static readonly KioskModeControllerFindByIdPath = '/api/kiosk-mode/{id}';

  /**
   * 키오스크 모드 조회.
   *
   * ID로 키오스크 모드를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerFindById$Response(params: KioskModeControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<KioskModeDto>> {
    return kioskModeControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 조회.
   *
   * ID로 키오스크 모드를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerFindById(params: KioskModeControllerFindById$Params, context?: HttpContext): Observable<KioskModeDto> {
    return this.kioskModeControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<KioskModeDto>): KioskModeDto => r.body)
    );
  }

  /** Path part for operation `kioskModeControllerRemove()` */
  static readonly KioskModeControllerRemovePath = '/api/kiosk-mode/{id}';

  /**
   * 키오스크 모드 삭제.
   *
   * 키오스크 모드를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerRemove$Response(params: KioskModeControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<KioskModeDto>> {
    return kioskModeControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 삭제.
   *
   * 키오스크 모드를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerRemove(params: KioskModeControllerRemove$Params, context?: HttpContext): Observable<KioskModeDto> {
    return this.kioskModeControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<KioskModeDto>): KioskModeDto => r.body)
    );
  }

  /** Path part for operation `kioskModeControllerUpdate()` */
  static readonly KioskModeControllerUpdatePath = '/api/kiosk-mode/{id}';

  /**
   * 키오스크 모드 수정.
   *
   * 키오스크 모드를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kioskModeControllerUpdate$Response(params: KioskModeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<KioskModeDto>> {
    return kioskModeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 수정.
   *
   * 키오스크 모드를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kioskModeControllerUpdate(params: KioskModeControllerUpdate$Params, context?: HttpContext): Observable<KioskModeDto> {
    return this.kioskModeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<KioskModeDto>): KioskModeDto => r.body)
    );
  }

  /** Path part for operation `kioskModeControllerUpdateMode()` */
  static readonly KioskModeControllerUpdateModePath = '/api/kiosk-mode/mode';

  /**
   * 키오스크 모드 변경.
   *
   * (키오스크 관리자) 키오스크 모드를 변경 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kioskModeControllerUpdateMode()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerUpdateMode$Response(params: KioskModeControllerUpdateMode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return kioskModeControllerUpdateMode(this.http, this.rootUrl, params, context);
  }

  /**
   * 키오스크 모드 변경.
   *
   * (키오스크 관리자) 키오스크 모드를 변경 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kioskModeControllerUpdateMode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kioskModeControllerUpdateMode(params: KioskModeControllerUpdateMode$Params, context?: HttpContext): Observable<void> {
    return this.kioskModeControllerUpdateMode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
