import { computed, inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CoordinateDto } from '@api-client';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular/common';
import { BehaviorSubject, filter } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { AuthStore } from './auth.store';
import { Preferences } from '@capacitor/preferences';
import { MEMBER_ACCESS_TOKEN_KEY } from '@common';

@Injectable({ providedIn: 'root' })
export class LocationStore {
  platform = inject(Platform);
  authStore = inject(AuthStore);

  socket: Socket | null = null;

  isLogined = computed(() => !!this.authStore.member());

  coordinate$ = new BehaviorSubject<CoordinateDto | null>(null);

  constructor() {
    toObservable(this.isLogined).subscribe(async (isLogined) => {
      if (!isLogined) {
        this.socket?.disconnect();
        this.socket = null;
        return;
      }

      // const accessToken = await Preferences.get({ key: MEMBER_ACCESS_TOKEN_KEY });

      // this.socket = io(`${environment.baseUrl}/location-tracker`, {
      //   auth: {
      //     memberId: this.authStore.member()!.id,
      //     accessToken: (await Preferences.get({ key: MEMBER_ACCESS_TOKEN_KEY })).value,
      //   },
      // });

      // const coordinate = this.coordinate$.getValue();
      // if (coordinate) this.socket.emit('location:report', coordinate);
    });

    // this.coordinate$.pipe(filter((coordinate) => !!coordinate)).subscribe((coordinate) => {
    //   this.socket?.emit('location:report', coordinate);
    // });

    if (this.platform.is('capacitor')) {
      Geolocation.checkPermissions().then((result) => {
        if (result.location === 'granted') {
          Geolocation.getCurrentPosition().then((position) => {
            this.coordinate$.next({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          });

          Geolocation.watchPosition({}, (position, err) => {
            if (err) {
              console.error(err);
              return;
            }

            if (position) {
              this.coordinate$.next({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            }
          });
        }
      });
    } else {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            this.coordinate$.next({ lat: position.coords.latitude, lng: position.coords.longitude });
          });

          navigator.geolocation.watchPosition((position) => {
            this.coordinate$.next({ lat: position.coords.latitude, lng: position.coords.longitude });
          });
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition((position) => {
            this.coordinate$.next({ lat: position.coords.latitude, lng: position.coords.longitude });
          });

          navigator.geolocation.watchPosition((position) => {
            this.coordinate$.next({ lat: position.coords.latitude, lng: position.coords.longitude });
          });
        }
      });
    }
  }
}
