/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { visitorStatsControllerFindVisitorStats } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats';
import { VisitorStatsControllerFindVisitorStats$Params } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats';
import { visitorStatsControllerFindVisitorStatsById } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats-by-id';
import { VisitorStatsControllerFindVisitorStatsById$Params } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats-by-id';
import { visitorStatsControllerFindVisitorStatsByUserId } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats-by-user-id';
import { VisitorStatsControllerFindVisitorStatsByUserId$Params } from '../fn/visitor-stats/visitor-stats-controller-find-visitor-stats-by-user-id';
import { visitorStatsControllerUpdateFavorite } from '../fn/visitor-stats/visitor-stats-controller-update-favorite';
import { VisitorStatsControllerUpdateFavorite$Params } from '../fn/visitor-stats/visitor-stats-controller-update-favorite';
import { visitorStatsControllerUpdateVisitorStats } from '../fn/visitor-stats/visitor-stats-controller-update-visitor-stats';
import { VisitorStatsControllerUpdateVisitorStats$Params } from '../fn/visitor-stats/visitor-stats-controller-update-visitor-stats';
import { VisitorStatsDto } from '../models/visitor-stats-dto';

@Injectable({ providedIn: 'root' })
export class VisitorStatsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `visitorStatsControllerFindVisitorStats()` */
  static readonly VisitorStatsControllerFindVisitorStatsPath = '/api/visitor-stats';

  /**
   * 방문자 통계 조회.
   *
   * 방문자 통계를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visitorStatsControllerFindVisitorStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStats$Response(params?: VisitorStatsControllerFindVisitorStats$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VisitorStatsDto>>> {
    return visitorStatsControllerFindVisitorStats(this.http, this.rootUrl, params, context);
  }

  /**
   * 방문자 통계 조회.
   *
   * 방문자 통계를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visitorStatsControllerFindVisitorStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStats(params?: VisitorStatsControllerFindVisitorStats$Params, context?: HttpContext): Observable<Array<VisitorStatsDto>> {
    return this.visitorStatsControllerFindVisitorStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VisitorStatsDto>>): Array<VisitorStatsDto> => r.body)
    );
  }

  /** Path part for operation `visitorStatsControllerFindVisitorStatsById()` */
  static readonly VisitorStatsControllerFindVisitorStatsByIdPath = '/api/visitor-stats/{id}';

  /**
   * ID로 방문자 통계 조회.
   *
   * ID로 방문자 통계를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visitorStatsControllerFindVisitorStatsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStatsById$Response(params: VisitorStatsControllerFindVisitorStatsById$Params, context?: HttpContext): Observable<StrictHttpResponse<VisitorStatsDto>> {
    return visitorStatsControllerFindVisitorStatsById(this.http, this.rootUrl, params, context);
  }

  /**
   * ID로 방문자 통계 조회.
   *
   * ID로 방문자 통계를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visitorStatsControllerFindVisitorStatsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStatsById(params: VisitorStatsControllerFindVisitorStatsById$Params, context?: HttpContext): Observable<VisitorStatsDto> {
    return this.visitorStatsControllerFindVisitorStatsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<VisitorStatsDto>): VisitorStatsDto => r.body)
    );
  }

  /** Path part for operation `visitorStatsControllerFindVisitorStatsByUserId()` */
  static readonly VisitorStatsControllerFindVisitorStatsByUserIdPath = '/api/visitor-stats/{userId}/user';

  /**
   * USER ID로 방문자 통계 조회.
   *
   * USER ID로 방문자 통계를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visitorStatsControllerFindVisitorStatsByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStatsByUserId$Response(params: VisitorStatsControllerFindVisitorStatsByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<VisitorStatsDto>> {
    return visitorStatsControllerFindVisitorStatsByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * USER ID로 방문자 통계 조회.
   *
   * USER ID로 방문자 통계를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visitorStatsControllerFindVisitorStatsByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerFindVisitorStatsByUserId(params: VisitorStatsControllerFindVisitorStatsByUserId$Params, context?: HttpContext): Observable<VisitorStatsDto> {
    return this.visitorStatsControllerFindVisitorStatsByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<VisitorStatsDto>): VisitorStatsDto => r.body)
    );
  }

  /** Path part for operation `visitorStatsControllerUpdateFavorite()` */
  static readonly VisitorStatsControllerUpdateFavoritePath = '/api/visitor-stats/{id}/favorite';

  /**
   * 단골 여부 수정.
   *
   * 단골 여부를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visitorStatsControllerUpdateFavorite()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerUpdateFavorite$Response(params: VisitorStatsControllerUpdateFavorite$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return visitorStatsControllerUpdateFavorite(this.http, this.rootUrl, params, context);
  }

  /**
   * 단골 여부 수정.
   *
   * 단골 여부를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visitorStatsControllerUpdateFavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerUpdateFavorite(params: VisitorStatsControllerUpdateFavorite$Params, context?: HttpContext): Observable<boolean> {
    return this.visitorStatsControllerUpdateFavorite$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `visitorStatsControllerUpdateVisitorStats()` */
  static readonly VisitorStatsControllerUpdateVisitorStatsPath = '/api/visitor-stats/visitor';

  /**
   * 방문자 통계 업데이트.
   *
   * 방문자 통계를 업데이트합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visitorStatsControllerUpdateVisitorStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerUpdateVisitorStats$Response(params?: VisitorStatsControllerUpdateVisitorStats$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return visitorStatsControllerUpdateVisitorStats(this.http, this.rootUrl, params, context);
  }

  /**
   * 방문자 통계 업데이트.
   *
   * 방문자 통계를 업데이트합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visitorStatsControllerUpdateVisitorStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visitorStatsControllerUpdateVisitorStats(params?: VisitorStatsControllerUpdateVisitorStats$Params, context?: HttpContext): Observable<void> {
    return this.visitorStatsControllerUpdateVisitorStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
